import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function IndusNetTechnologiesPvtLtd() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c5_51212bc176.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Indus Net Technologies Pvt Ltd
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                Third, on the list of{" "}
                <b>top 10 UI UX design companies in Chennai</b> is the
                award-winning design company Indus Net Technologies.
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $25-$49 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 250-999
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.indusnet.co.in/" target="_blank">
                  https://www.indusnet.co.in/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | 4th Floor, SDF Building Saltlake Electronic Complex,
                  Kolkata, West Bengal 700091
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                Third, on the list of{" "}
                <b>top 10 UI UX design companies in Chennai</b> is the
                award-winning design company Indus Net Technologies. The company
                has been providing end to end digital solutions for businesses
                since 1997. Ever since it was founded, the company has grown to
                house over 750 tech professionals today. The team at Indus Net
                Technologies takes pride in the fact that they provide simple
                yet sustainable solutions that help clients to achieve digital
                success. The team has an analytics-driven approach. With
                branches in almost all prime cities across the Indian
                subcontinent as well as abroad, the team has worked with several
                clients from Induslnd Bank to Government of India to Tesco Bank
                to Cipla to Dr. Reddy's.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
